import React from "react";

const Recherche_generateur = ( ) => {
    return(
        <div class="card card-body">
            <div class="row">
                <div className="col-md-4">
                    <label>Immatriculation</label>
                    <input type="text" className="form-control"/>
                </div>
            </div>
        </div>
    )
}

export default Recherche_generateur;