import React from 'react'
import Navbar from '../../Navbar'

const AffichetousId = () => {
  return (
    <>
        <Navbar />
        dss
    </>
  )
}

export default AffichetousId